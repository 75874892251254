import React, { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { DateText } from "@cpt/components";
import type { StripeSubscriptionServerLicensingChannelDto } from "client/api/ServerLicenseApi";
import { UpgradePlan } from "client/api/UpgradePlan";
import { ChangePlanFinanceLine } from "components/ChangePlanFinanceLine/ChangePlanFinanceLine";
import { GridDivider } from "components/GridDivider/GridDivider";
import { LinkButton } from "components/LinkButton/LinkButton";
import { RouterLink } from "components/RouterLink/RouterLink";
import { themeTokens } from "components/Theme/theme";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { CustomerPortalLink } from "areas/cloudSubscriptions/components/CustomerPortalLink";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";
import {
  ServerLicenseCancelPlanDialog,
  ServerLicenseCancelPlanDialogText,
} from "areas/serverLicenses/detail/components/ServerLicenseCancelPlanDialog";
import type { ServerLicensingChannelViewProps } from "areas/serverLicenses/detail/components/finance/ServerLicensingChannelViewProps";

export function StripeSubscriptionServerLicensingChannelView(
  props: ServerLicensingChannelViewProps<StripeSubscriptionServerLicensingChannelDto>
) {
  const { channel, serverLicense } = props;
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const isCancelled = channel.status === "canceled";
  const isCancelledOrCancelling = channel.status === "canceled" || channel.cancelAtPeriodEnd;
  const showCustomStripeControls = !isCancelledOrCancelling;
  const manageButtonText = isCancelledOrCancelling ? "Resubscribe" : "Manage subscription";
  const isSubscriptionPtm = [UpgradePlan.Server_Annually_Starter].some((val) => val === channel.priceCode);
  const isCanceledNonPtm = !isSubscriptionPtm && isCancelledOrCancelling;
  const showResubscribe = !isCanceledNonPtm;
  const planStatusPrefix =
    channel.status === "canceled" ? "Cancelled on" : channel.cancelAtPeriodEnd ? "Cancels on" : "Renews on";

  return (
    <>
      <Grid container data-testid={"current-plan-finance-line"}>
        <Grid item sm={3} sx={{ padding: 3 }}>
          <Typography fontWeight={700}>Current plan</Typography>
        </Grid>
        <Grid item sm={6} sx={{ padding: 3 }}>
          <Stack spacing={0}>
            <Typography>{channel.productName}</Typography>
            <Typography sx={{ color: themeTokens.color.text.secondary }}>
              {planStatusPrefix} <DateText date={channel.nextRenewalDate} />
            </Typography>
          </Stack>
        </Grid>
        <Grid item sm={3} sx={{ padding: 3 }}>
          <Stack spacing={1} sx={{ alignItems: "flex-end" }}>
            {showResubscribe && (
              <CustomerPortalLink text={manageButtonText} productType={"ServerLicense"} productId={serverLicense.id} />
            )}
            {showCustomStripeControls && (
              <PolicyAuthorized
                permission={RbacPermissions.ServerLicense.ManageBilling}
                assetId={serverLicense?.id}
                subscriptionGroupId={serverLicense?.subscriptionGroup?.id}
              >
                <RouterLink to={ServerLicensesRoutesMap.detail(serverLicense.id).upgrade}>Change plan</RouterLink>
                {!isCancelledOrCancelling && (
                  <>
                    <LinkButton onClick={() => setShowCancelDialog(true)}>Cancel subscription</LinkButton>
                    <ServerLicenseCancelPlanDialog
                      open={showCancelDialog}
                      onClose={() => setShowCancelDialog(false)}
                      serverLicense={serverLicense}
                    >
                      <ServerLicenseCancelPlanDialogText
                        channelType={channel.channelType}
                        endDate={channel.nextRenewalDate}
                      />
                    </ServerLicenseCancelPlanDialog>
                  </>
                )}
              </PolicyAuthorized>
            )}
          </Stack>
        </Grid>
      </Grid>
      <GridDivider />
      {!showCustomStripeControls && !isCancelled && <ChangePlanFinanceLine />}
    </>
  );
}
