import React from "react";
import type { CloudSubscriptionDto, ZuoraCloudSubscriptionLicensingChannelDto } from "client/api/CloudSubscriptionApi";
import { ChangePlanFinanceLine } from "components/ChangePlanFinanceLine/ChangePlanFinanceLine";
import { GridDivider } from "components/GridDivider/GridDivider";
import { LicensedEntitlementsView } from "components/LicensedEntitlementsView/LicensedEntitlementsView";
import { OrdersFinanceLine } from "components/OrdersFinanceLine/OrdersFinanceLine";
import type { LicensingChannelViewProps } from "areas/cloudSubscriptions/detail/components/finance/LicensingChannelViewProps";

type EntitlementViewProps = { cloudSubscription: CloudSubscriptionDto };
function EntitlementView(props: EntitlementViewProps) {
  const { cloudSubscription } = props;
  return <LicensedEntitlementsView license={cloudSubscription} />;
}

export function ZuoraLicensingChannelView(props: LicensingChannelViewProps<ZuoraCloudSubscriptionLicensingChannelDto>) {
  const { cloudSubscription } = props;
  return (
    <>
      <ChangePlanFinanceLine />
      <GridDivider />
      <EntitlementView cloudSubscription={cloudSubscription} />
      <GridDivider />
      <OrdersFinanceLine cloudSubscription={cloudSubscription} />
    </>
  );
}
