import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, debounce, Divider, styled } from "@mui/material";
import Environment from "environment";
import { ErrorBoundary } from "react-error-boundary";
import {
  getGeolocation,
  getOrderPreview,
  type OrderPreview,
  type OrderPreviewRequest,
  ProductType,
} from "client/api/PurchaseApi";
import { CustomErrorAlert, ErrorMessageTryAgainOrContactSupport } from "components/alert/CustomErrorAlert";
import { NotFoundPage } from "pages/NotFoundPage";
import { isDropdownAutocompleteOption } from "areas/checkout/components/CheckoutDropdownInput";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";
import { CheckoutComplete } from "areas/purchasing/CheckoutComplete";
import { CheckoutOrderSummary } from "areas/purchasing/components/CheckoutOrderSummary/CheckoutOrderSummary";
import { CheckoutPaymentStep } from "areas/purchasing/components/CheckoutPaymentStep";
import type {
  CheckoutCompanyForm,
  CheckoutIndividualForm,
  CheckoutPersonalDetailsInputs,
  PurchasingAs,
} from "areas/purchasing/components/CheckoutPersonalDetailsStep";
import { CheckoutPersonalDetailsStep } from "areas/purchasing/components/CheckoutPersonalDetailsStep";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";
import { CheckoutLayout } from "./CheckoutLayout";
import { CheckoutContactSalesReasons, CheckoutContactSalesStep } from "./components/CheckoutContactSalesStep";
import { StepProgress } from "./components/CheckoutStepProgress";
import { useZuoraLibraryScriptLoader } from "./components/CheckoutZuoraIframe";

type SubscriptionType = "cloud" | "selfhosted";

export interface OrderPreviewErrorReason {
  statusCode: number;
  message: string;
}

export const CheckoutStepNames = {
  PersonalDetails: "PersonalDetails",
  Payment: "Payment",
  ContactSales: "ContactSales",
} as const;

const StickySidebar = styled("div")`
  position: sticky;
  top: 0;
`;

const isCheckoutCompanyForm = (formData: CheckoutPersonalDetailsInputs): formData is CheckoutCompanyForm => {
  return formData.purchasingAs === "company";
};

const isCheckoutIndividualForm = (formData: CheckoutPersonalDetailsInputs): formData is CheckoutIndividualForm => {
  return formData.purchasingAs === "individual";
};

export function Checkout() {
  const [searchParams] = useSearchParams();
  const subscriptionId = searchParams.get("subscriptionId");
  const subscriptionType = searchParams.get("subscriptionType") ?? "";
  const [currentStep, setCurrentStep] = useState(1);
  const [orderPreview, setOrderPreview] = useState<OrderPreview | null>(null);
  const [isLoadingOrderPreview, setIsLoadingOrderPreview] = useState<boolean>(false);
  const [isTaxExempted, setTaxExempted] = useState(false);
  const [personalDetailsStepData, setStepOneData] = useState<CheckoutPersonalDetailsInputs>({
    billingContact: {
      firstName: "",
      lastName: "",
      email: "",
      streetAddress: "",
      country: null,
      city: "",
      state: "",
      postcode: "",
    },
    companyContact: {
      name: "",
      streetAddress: "",
      country: null,
      city: "",
      state: "",
      postcode: "",
    },
    taxId: "",
    purchasingAs: null,
  });
  const [geolocationIsoCode, setGeolocationIsoCode] = useState<string>();
  const [orderPreviewErrorReason, setOrderPreviewErrorReason] = useState<OrderPreviewErrorReason | null>(null);
  const { isZuoraLibraryReady, error: zuoraScriptLibraryError } = useZuoraLibraryScriptLoader();
  const billingProduct = subscriptionType === "cloud" ? ProductType.StarterCloud : ProductType.StarterServer;

  const taxAddress = (() => {
    if (personalDetailsStepData.purchasingAs === null) {
      return null;
    }
    if (isCheckoutCompanyForm(personalDetailsStepData)) {
      return personalDetailsStepData.companyContact;
    }
    if (isCheckoutIndividualForm(personalDetailsStepData)) {
      return personalDetailsStepData.billingContact;
    }
    return null;
  })();

  const hasMinimumTaxInfo = !!(taxAddress && taxAddress.country && taxAddress.streetAddress);

  /** get geolocation on initial render */
  useEffect(() => {
    const controller = new AbortController();
    const envClientIpAddress = Environment.clientIpAddress();
    const fetchData = async () => {
      return await getGeolocation(envClientIpAddress, controller.signal);
    };

    try {
      (async () => {
        const data = await fetchData();
        if (data.success) {
          setGeolocationIsoCode(data.isoCode);
        }
      })();
    } catch (err) {
      // it doesn't really matter if we weren't able to fetch geolocation as it's only used to prepopulate country
    }

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    // don't make unnecessary requests when purchasingAs is set and an address is not entered, note this still allows the initial order preview request to go ahead
    if (personalDetailsStepData.purchasingAs && !hasMinimumTaxInfo) {
      return;
    }

    setOrderPreviewErrorReason(null);
    setIsLoadingOrderPreview(true);

    const request: OrderPreviewRequest = {
      product: billingProduct,
      city: taxAddress?.city,
      county: "",
      country: taxAddress?.country?.value,
      postalCode: taxAddress?.postcode,
      state: taxAddress
        ? isDropdownAutocompleteOption(taxAddress.state)
          ? taxAddress.state.value
          : taxAddress.state ?? ""
        : "",
    };

    const controller = new AbortController();

    const fetchData = async () => {
      const response = await getOrderPreview(request, controller.signal);
      setOrderPreview(response);
      setIsLoadingOrderPreview(false);
    };

    fetchData().catch((e) => {
      if (!controller.signal.aborted) {
        setIsLoadingOrderPreview(false);
        setOrderPreviewErrorReason({
          statusCode: e.response?.status,
          message: e.message,
        });
      }
    });

    return () => {
      setIsLoadingOrderPreview(false);
      controller.abort();
    };
  }, [
    taxAddress,
    taxAddress?.country?.value,
    taxAddress?.city,
    taxAddress?.state,
    taxAddress?.postcode,
    billingProduct,
    personalDetailsStepData.purchasingAs,
    hasMinimumTaxInfo,
  ]);

  const advanceStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const toggleTaxExempted = () => {
    setTaxExempted(!isTaxExempted);
  };

  const onSubmitPersonalDetails = (data: CheckoutPersonalDetailsInputs) => {
    setStepOneData(data);
    advanceStep();
  };

  const onAddressChanged = debounce((data: CheckoutPersonalDetailsInputs) => {
    setStepOneData(data);
  }, 500);

  const onPurchasingAsChanged = (data: CheckoutPersonalDetailsInputs) => {
    setStepOneData(data);
  };

  if (!subscriptionId) {
    return <NotFoundPage />;
  }
  if (subscriptionType !== "cloud" && subscriptionType !== "selfhosted") {
    return <NotFoundPage />;
  }
  if (zuoraScriptLibraryError) {
    return <CustomErrorAlert message={<ErrorMessageTryAgainOrContactSupport />} />;
  }

  return (
    <ErrorBoundary fallback={<CustomErrorAlert message={<ErrorMessageTryAgainOrContactSupport />} />}>
      <CheckoutLayout subscriptionId={subscriptionId} subscriptionType={subscriptionType}>
        <Box sx={{ paddingTop: "16px", paddingBottom: "14px" }}>
          <StepProgress
            currentStep={currentStep}
            stepTitles={!isTaxExempted ? ["Information", "Payment"] : ["Information", "Contact Sales"]}
          />
        </Box>
        <Divider sx={{ borderColor: "#E6E8EA" }} />

        <Steps
          currentStep={currentStep}
          hasOrderPreviewError={!!orderPreviewErrorReason}
          onSubmitPersonalDetails={onSubmitPersonalDetails}
          onAddressChanged={onAddressChanged}
          onPurchasingAsChanged={onPurchasingAsChanged}
          personalDetailsStepData={personalDetailsStepData}
          orderPreview={orderPreview}
          orderSummary={
            <StickySidebar>
              <CheckoutOrderSummary
                subscriptionType={subscriptionType}
                isTaxExempted={isTaxExempted}
                onToggleTaxExemption={toggleTaxExempted}
                changePlanRoute={getChangePlanRoute(subscriptionId, subscriptionType)}
                orderPreview={orderPreview}
                errorReason={orderPreviewErrorReason}
                isFetchingTax={isLoadingOrderPreview}
                hasMinimumTaxInfo={hasMinimumTaxInfo}
              />
            </StickySidebar>
          }
          advanceStep={advanceStep}
          backStep={backStep}
          subscriptionType={subscriptionType}
          billingProduct={billingProduct}
          subscriptionId={subscriptionId}
          isZuoraLibraryReady={isZuoraLibraryReady}
          isTaxExempted={isTaxExempted}
          geolocationIsoCode={geolocationIsoCode}
        />
      </CheckoutLayout>
    </ErrorBoundary>
  );
}

const getStep2 = ({ isTaxExempted }: { isTaxExempted: boolean }) => {
  const contactSalesReason = (() => {
    if (isTaxExempted) {
      return CheckoutContactSalesReasons.TaxExemption;
    }
    return false;
  })();

  if (contactSalesReason) {
    return { step: CheckoutStepNames.ContactSales, reason: contactSalesReason };
  }

  return { step: CheckoutStepNames.Payment };
};

function assertSelectedPurchasingAs(val: PurchasingAs): asserts val is "company" | "individual" {
  if (val != "company" && val != "individual") {
    throw new TypeError("Invalid purchasingAs option");
  }
}

const Step2 = ({
  billingProduct,
  personalDetailsStepData,
  orderSummary,
  backStep,
  onOrderComplete,
  isZuoraLibraryReady,
  step,
}: {
  billingProduct: ProductType;
  personalDetailsStepData: CheckoutPersonalDetailsInputs;
  orderSummary: React.ReactElement;
  backStep: () => void;
  onOrderComplete: () => void;
  isZuoraLibraryReady: boolean;
  step:
    | {
        step: typeof CheckoutStepNames.ContactSales;
        reason: keyof typeof CheckoutContactSalesReasons;
      }
    | {
        step: typeof CheckoutStepNames.Payment;
      };
}) => {
  assertSelectedPurchasingAs(personalDetailsStepData.purchasingAs);

  if (step.step === CheckoutStepNames.ContactSales) {
    return (
      <CheckoutContactSalesStep
        personalDetailsStepData={personalDetailsStepData}
        billingProduct={billingProduct}
        orderSummary={orderSummary}
        onBack={backStep}
        reason={step.reason}
      />
    );
  }

  return (
    <CheckoutPaymentStep
      orderSummary={orderSummary}
      personalStepDetails={personalDetailsStepData}
      billingProduct={billingProduct}
      onOrderComplete={onOrderComplete}
      onBack={backStep}
      isZuoraLibraryReady={isZuoraLibraryReady}
    />
  );
};

const Steps = ({
  currentStep,
  advanceStep,
  backStep,
  hasOrderPreviewError,
  orderSummary,
  orderPreview,
  subscriptionId,
  subscriptionType,
  billingProduct,
  onSubmitPersonalDetails,
  onAddressChanged,
  onPurchasingAsChanged,
  personalDetailsStepData,
  isZuoraLibraryReady,
  isTaxExempted,
  geolocationIsoCode,
}: {
  currentStep: number;
  advanceStep: () => void;
  backStep: () => void;
  hasOrderPreviewError: boolean;
  onSubmitPersonalDetails: (data: CheckoutPersonalDetailsInputs) => void;
  onAddressChanged: (data: CheckoutPersonalDetailsInputs) => void;
  onPurchasingAsChanged: (data: CheckoutPersonalDetailsInputs) => void;
  personalDetailsStepData: CheckoutPersonalDetailsInputs;
  orderSummary: React.ReactElement;
  orderPreview: OrderPreview | null;
  subscriptionId: string;
  subscriptionType: SubscriptionType;
  billingProduct: ProductType;
  isZuoraLibraryReady: boolean;
  isTaxExempted: boolean;
  geolocationIsoCode?: string;
}) => {
  const navigate = useNavigate();

  const onOrderComplete = () => {
    advanceStep();
  };

  const step2 = getStep2({ isTaxExempted });

  return (
    <>
      <CheckoutPersonalDetailsStep
        sx={{ display: currentStep === 1 ? "flex" : "none" }}
        orderSummary={orderSummary}
        onSubmit={onSubmitPersonalDetails}
        onAddressChanged={onAddressChanged}
        onPurchasingAsChanged={onPurchasingAsChanged}
        hasOrderPreviewError={hasOrderPreviewError}
        initialData={personalDetailsStepData}
        orderPreview={orderPreview}
        geolocationIsoCode={geolocationIsoCode}
        onBack={() =>
          navigate({
            pathname: getChangePlanRoute(subscriptionId, subscriptionType),
          })
        }
        nextStep={step2.step}
      />
      {currentStep === 2 && (
        <Step2
          orderSummary={orderSummary}
          personalDetailsStepData={personalDetailsStepData}
          billingProduct={billingProduct}
          backStep={backStep}
          onOrderComplete={onOrderComplete}
          isZuoraLibraryReady={isZuoraLibraryReady}
          step={step2}
        />
      )}
      {currentStep === 3 && <CheckoutComplete subscriptionId={subscriptionId} subscriptionType={subscriptionType} />}
    </>
  );
};

function getChangePlanRoute(subscriptionId: string, subscriptionType: SubscriptionType) {
  return subscriptionType === "cloud"
    ? CloudSubscriptionsRoutesMap.detail(subscriptionId).upgrade
    : ServerLicensesRoutesMap.detail(subscriptionId).upgrade;
}
