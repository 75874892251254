import React from "react";
import type { ReactNode } from "react";
import { Typography, Alert, AlertTitle, Link } from "@mui/material";
import { ExternalUriMap } from "utils/ExternalUriMap";

export const ErrorMessageTryAgainOrContactSupport = () => {
  return (
    <Typography variant="subtitle1" gutterBottom component="div">
      Please try again. If you need help, please contact our friendly{" "}
      <Link
        underline={"none"}
        fontWeight={500}
        target="_blank"
        rel="noopener"
        href={ExternalUriMap.octopusComSupportRootPath}
      >
        support
      </Link>{" "}
      team
    </Typography>
  );
};

export const ErrorMessageContactSupport = () => {
  return (
    <Typography variant="subtitle1" gutterBottom component="div">
      Please contact our friendly{" "}
      <Link
        underline={"none"}
        fontWeight={500}
        target="_blank"
        rel="noopener"
        href={ExternalUriMap.octopusComSupportRootPath}
      >
        support
      </Link>{" "}
      team
    </Typography>
  );
};

export const CustomErrorAlert = ({
  title = "Something went wrong!",
  message,
}: {
  title?: ReactNode;
  message: ReactNode;
}) => {
  return (
    <Alert severity="error">
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
};
