import type { ComponentType } from "react";
import React, { useState } from "react";
import type { ButtonBaseProps } from "@mui/material";
import { CircularProgress, Typography } from "@mui/material";
import { navigateToStripeCheckoutPage } from "utils/navigationHelpers";
import type { ProductType } from "shared/ProductType";
import type { UpgradePlan } from "client/api/UpgradePlan";

interface StripeCheckoutButtonProps {
  productType: ProductType;
  productId: string;
  targetPlan: UpgradePlan;
  Button: ComponentType<ButtonBaseProps>;
}

export function StripeCheckoutButton(props: StripeCheckoutButtonProps) {
  const { productType, productId, targetPlan, Button } = props;
  const [loadingPortal, setLoadingPortal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();

  const navigateToStripePortal = async () => {
    try {
      setLoadingPortal(true);
      await navigateToStripeCheckoutPage(productType, productId, targetPlan, null);
    } catch (e) {
      setError(true);
    } finally {
      setLoadingPortal(false);
    }
  };

  if (loadingPortal) {
    return <CircularProgress size={18} />;
  }
  return error ? (
    <Typography>Sorry, something went wrong. Please contact support.</Typography>
  ) : (
    <Button onClick={navigateToStripePortal} />
  );
}
