import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button } from "@mui/material";
import type { DateTime } from "luxon";
import { useFeatureFlag } from "contexts/ConfigurationProvider/ConfigurationProvider";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import {
  IsServerLicenseStripeSubscriptionLicensingChannel,
  hasPriceCode,
  hasStatus,
  isServerLicenseTrialLicensingChannel,
} from "utils/ServerLicenseLicensingChannelTypeHelpers";
import type { ServerLicenseDto, ServerLicensingChannel } from "client/api/ServerLicenseApi";
import { ServerLicensingChannelTypesKeys } from "client/api/ServerLicenseApi";
import { UpgradePlan } from "client/api/UpgradePlan";
import { GreenButton } from "components/Button/GreenButton";
import { useContactSalesDialog } from "components/Dialogs/ContactSalesDialog/ContactSalesDialogProvider";
import type { ContactSalesReason } from "components/Dialogs/ContactSalesDialog/ContactSalesReasons";
import { StripeCheckoutButton } from "components/StripeCheckoutButton/StripeCheckoutButton";
import { PurchasingRoutesMap } from "areas/purchasing/PurchasingRoutesMap";
import { ServerLicenseCancelPlanDialog, ServerLicenseCancelPlanDialogText } from "../ServerLicenseCancelPlanDialog";
import { ConfirmStripeUpgradeDialog } from "./ConfirmStripeUpgradeDialog";

const isAllowedSelfService = ({
  licensingChannel,
  priceCode,
  targetPriceCode,
}: {
  licensingChannel: ServerLicensingChannel;
  priceCode: string | undefined;
  targetPriceCode: string;
}) => {
  const isAllowedChannel = isAllowedSelfServiceLicensingChannel(licensingChannel);
  const isAllowedTransition = isAllowedPlanTransition({ licensingChannel, priceCode, targetPriceCode });

  return isAllowedChannel && isAllowedTransition;
};

const isAllowedPlanTransition = ({
  licensingChannel,
  priceCode,
  targetPriceCode,
}: {
  licensingChannel: ServerLicensingChannel;
  priceCode: string | undefined;
  targetPriceCode: string;
}) => {
  let isAllowedPlanTransition = false;
  const trialBrand = "Trial";
  const planBrand = isServerLicenseTrialLicensingChannel(licensingChannel) ? trialBrand : priceCode?.toLowerCase();
  const allowedPlanTransitions = getAllowedSelfServicePlanTransitions();

  if (typeof planBrand === "string" && allowedPlanTransitions[planBrand]?.includes(targetPriceCode)) {
    isAllowedPlanTransition = true;
  }

  return isAllowedPlanTransition;
};

const isAllowedSelfServiceLicensingChannel = (licensingChannel: ServerLicensingChannel) => {
  if (IsServerLicenseStripeSubscriptionLicensingChannel(licensingChannel)) {
    return true;
  }
  return !!isServerLicenseTrialLicensingChannel(licensingChannel);
};

/**
 * Maps the allowed transitions between different plans, for Stripe licenses using priceCode, for Trials using "Trial" as brand
 * @returns allowed transitions
 */
const getAllowedSelfServicePlanTransitions = () => {
  const trialBrand = "Trial";
  const allowed: { [id: string]: string[] } = {
    [trialBrand]: [UpgradePlan.Server_Annually_Starter],
  };
  return allowed;
};

export function getUpgradeAction(
  pricingCardPriceCode: string,
  licensingChannel: ServerLicensingChannel
): UpgradeActions {
  if (!isAllowedSelfServiceLicensingChannel(licensingChannel)) return "contact-sales";
  if (hasStatus(licensingChannel) && licensingChannel.status === "canceled") return "contact-sales";
  if (
    IsServerLicenseStripeSubscriptionLicensingChannel(licensingChannel) &&
    pricingCardPriceCode == licensingChannel.priceCode
  ) {
    return "no-change";
  }

  if (
    isAllowedSelfService({
      licensingChannel,
      priceCode: (hasPriceCode(licensingChannel) && licensingChannel.priceCode) || undefined,
      targetPriceCode: pricingCardPriceCode,
    })
  ) {
    return isServerLicenseTrialLicensingChannel(licensingChannel) ? "self-service-purchase" : "self-service-upgrade";
  }

  return "contact-sales";
}

export type UpgradeActions = "self-service-purchase" | "self-service-upgrade" | "contact-sales" | "no-change";

export type PricingCardActionProps = {
  serverLicenseId: string;
  action: UpgradeActions;
  targetPlan: UpgradePlan;
  targetPlanName: string;
  projectQuantity: string;
  nextRenewalDate?: DateTime;
};

export function PricingCardAction(props: PricingCardActionProps) {
  const { serverLicenseId, action, targetPlan, targetPlanName, nextRenewalDate, projectQuantity } = props;

  const { showDialog: showContactSalesDialog } = useContactSalesDialog();
  const [showUpgradeDialog, setShowUpgradeDialog] = useState<boolean>(false);
  const useZuoraCheckout = useFeatureFlag("ZuoraCheckout");
  const navigate = useNavigate();

  const subscribeButton = (
    <>
      {useZuoraCheckout ? (
        <GreenButton
          variant="contained"
          size="large"
          disableElevation
          sx={{ textTransform: "none", width: "100%", marginTop: "0.75rem", backgroundColor: "#00B065" }}
          onClick={() =>
            navigate({
              pathname: PurchasingRoutesMap.payment,
              search: `subscriptionId=${serverLicenseId}&subscriptionType=selfhosted`,
            })
          }
          endIcon={<ChevronRightIcon />}
        >
          Subscribe
        </GreenButton>
      ) : (
        <StripeCheckoutButton
          Button={({ onClick }) => (
            <GreenButton
              variant="contained"
              size="large"
              disableElevation
              sx={{ textTransform: "none", width: "100%", marginTop: "0.75rem", backgroundColor: "#00B065" }}
              onClick={onClick}
              endIcon={<ChevronRightIcon />}
            >
              Subscribe via Stripe
            </GreenButton>
          )}
          productType="ServerLicense"
          productId={serverLicenseId}
          targetPlan={targetPlan}
        />
      )}
    </>
  );

  const upgradeButton = (
    <>
      <GreenButton
        variant="contained"
        onClick={() => setShowUpgradeDialog(true)}
        size="large"
        disableElevation
        sx={{ textTransform: "none", width: "100%", marginTop: "0.75rem", backgroundColor: "#00B065" }}
        endIcon={<ChevronRightIcon />}
      >
        Upgrade via Stripe
      </GreenButton>
      <ConfirmStripeUpgradeDialog
        open={showUpgradeDialog}
        setOpen={setShowUpgradeDialog}
        productName={targetPlanName}
        serverLicenseId={serverLicenseId}
        targetPlan={targetPlan}
        varyByTarget={false}
        nextRenewalDate={nextRenewalDate}
      />
    </>
  );

  const contactSalesButton = (targetPlan: UpgradePlan) => {
    let reason: ContactSalesReason = "modify-subscription";
    switch (targetPlan) {
      case UpgradePlan.Server_Annually_Starter:
        reason = "starter";
        break;
      case UpgradePlan.Server_Annually_Professional:
        reason = "professional";
        break;
      case UpgradePlan.Server_Annually_Enterprise:
        reason = "enterprise";
        break;
    }
    return (
      <>
        <GreenButton
          variant="contained"
          onClick={() => showContactSalesDialog({ reason, projectQuantity })}
          size="large"
          disableElevation
          sx={{ textTransform: "none", width: "100%", marginTop: "0.75rem", backgroundColor: "#00B065" }}
        >
          Contact Sales
        </GreenButton>
      </>
    );
  };

  const CancelButton = () => {
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const { entity: serverLicense } = useEntity<ServerLicenseDto>();

    switch (serverLicense?.licensingChannel.channelType) {
      case ServerLicensingChannelTypesKeys.Stripe:
        return (
          <>
            <Button
              variant="outlined"
              size="large"
              disableElevation
              sx={{ width: "100%", marginTop: "0.75rem" }}
              onClick={() => setShowCancelDialog(true)}
            >
              Cancel
            </Button>
            <ServerLicenseCancelPlanDialog
              open={showCancelDialog}
              onClose={() => setShowCancelDialog(false)}
              serverLicense={serverLicense}
            >
              <ServerLicenseCancelPlanDialogText
                channelType={serverLicense.licensingChannel.channelType}
                endDate={serverLicense.licensingChannel.nextRenewalDate}
              />
            </ServerLicenseCancelPlanDialog>
          </>
        );
      case ServerLicensingChannelTypesKeys.Trial:
        return (
          <>
            <Button
              variant="outlined"
              size="large"
              disableElevation
              sx={{ width: "100%", marginTop: "0.75rem" }}
              onClick={() => setShowCancelDialog(true)}
            >
              Cancel
            </Button>
            <ServerLicenseCancelPlanDialog
              open={showCancelDialog}
              onClose={() => setShowCancelDialog(false)}
              serverLicense={serverLicense}
            >
              <ServerLicenseCancelPlanDialogText
                channelType={serverLicense.licensingChannel.channelType}
                endDate={serverLicense.licensingChannel.expiryDate}
              />
            </ServerLicenseCancelPlanDialog>
          </>
        );

      default:
        return (
          <Button
            variant="outlined"
            size="large"
            disableElevation
            sx={{ width: "100%", marginTop: "0.75rem" }}
            onClick={() => showContactSalesDialog({ reason: "modify-subscription" })}
          >
            Cancel
          </Button>
        );
    }
  };

  let button = <></>;
  switch (action) {
    case "self-service-purchase":
      button = subscribeButton;
      break;
    case "self-service-upgrade":
      button = upgradeButton;
      break;
    case "contact-sales":
      button = contactSalesButton(targetPlan);
      break;
    case "no-change":
      button = <CancelButton />;
      break;
  }

  return button;
}
