// Wrapper functions for methods that don't exist in JSdom, since it's easier to mock these than it is to mock JSDom implementations
// All of these functions are auto mocked - See testUtils/setup for details;
import type { ProductType } from "shared/ProductType";
import type { CheckoutSessionDto } from "client/api/CheckoutSessionDto";
import type { CustomerPortalSessionDto } from "client/api/CloudSubscriptionApi";
import { createCloudSubscriptionCheckoutUrl } from "client/api/CloudSubscriptionApi";
import { createCloudSubscriptionCustomerPortalUrl } from "client/api/CloudSubscriptionApi";
import { createServerLicenseCheckoutUrl, createServerLicenseCustomerPortalUrl } from "client/api/ServerLicenseApi";
import type { UpgradePlan } from "client/api/UpgradePlan";

export const navigateToExternalSite = (url: string): void => window.location.assign(url);
export const openTabToExternalSite = (url: string): void => window.open(url, "_blank")?.focus();

export const navigateToStripeCustomerPortal = async (
  productType: ProductType,
  productId: string,
  newTab: boolean = false
): Promise<void> => {
  let customerPortalSession: CustomerPortalSessionDto | null = null;

  switch (productType) {
    case "CloudSubscription":
      customerPortalSession = await createCloudSubscriptionCustomerPortalUrl(productId, window.location.href);
      break;
    case "ServerLicense":
      customerPortalSession = await createServerLicenseCustomerPortalUrl(productId, window.location.href);
      break;
  }

  if (customerPortalSession !== null) {
    newTab ? openTabToExternalSite(customerPortalSession.url) : navigateToExternalSite(customerPortalSession.url);
  }
};

export const navigateToStripeCheckoutPage = async (
  productType: ProductType,
  productId: string,
  targetPlan: UpgradePlan,
  machineLimitOverride: number | null
): Promise<void> => {
  let session: CheckoutSessionDto | null = null;

  switch (productType) {
    case "CloudSubscription":
      session = await createCloudSubscriptionCheckoutUrl(productId, targetPlan, machineLimitOverride);
      break;
    case "ServerLicense":
      session = await createServerLicenseCheckoutUrl(productId, targetPlan);
      break;
  }

  if (session !== null) {
    navigateToExternalSite(session.checkoutUrl);
  }
};
