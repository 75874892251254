import React from "react";
import type { InputBaseComponentProps } from "@mui/material";
import { Input, InputAdornment, styled } from "@mui/material";
import { AsteriskIcon } from "./AsteriskIcon";

interface CheckoutTextInputProps {
  id?: string;
  inputProps?: InputBaseComponentProps;
  required?: boolean;
  error?: boolean;
  placeholder?: string;
  endAdornment?: React.ReactNode;
  fullWidth?: boolean;
  value?: unknown;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const TextInput = styled(Input)`
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid #7c98b4;
  border-radius: 8px;
  height: 50px;
  &.Mui-focused:not(.Mui-error) {
    border-color: #0d80d8;
  }
  &.Mui-error {
    border-color: #c95340;
  }
  input {
    box-sizing: border-box;
    padding: 13px 20px;
    height: 100%;
  }
  .MuiInputAdornment-positionEnd {
    padding-right: 20px;
    margin-left: 0;
  }
`;

export function CheckoutTextInput(props: CheckoutTextInputProps) {
  const { id, required, placeholder, error, inputProps, endAdornment, fullWidth = true, value, onChange } = props;

  return (
    <TextInput
      id={id}
      fullWidth={fullWidth}
      inputProps={inputProps}
      placeholder={placeholder}
      disableUnderline={true}
      error={error}
      value={value}
      onChange={onChange}
      endAdornment={
        endAdornment ??
        (required && (
          <InputAdornment position={"end"}>
            <AsteriskIcon error={error} />
          </InputAdornment>
        ))
      }
    />
  );
}
