import React, { useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { navigateToStripeCustomerPortal } from "utils/navigationHelpers";
import type { ProductType } from "shared/ProductType";
import { LinkButton } from "components/LinkButton/LinkButton";

interface CustomerPortalLinkProps {
  text: string;
  productType: ProductType;
  productId: string;
}

export function CustomerPortalLink(props: CustomerPortalLinkProps) {
  const { text, productType, productId } = props;
  const [loadingPortal, setLoadingPortal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();

  const navigateToStripePortal = async () => {
    try {
      setLoadingPortal(true);
      await navigateToStripeCustomerPortal(productType, productId);
    } catch (e) {
      setError(true);
    } finally {
      setLoadingPortal(false);
    }
  };

  if (loadingPortal) {
    return <CircularProgress size={18} />;
  }
  return error ? (
    <Typography>Sorry, something went wrong. Please contact support.</Typography>
  ) : (
    <LinkButton onClick={navigateToStripePortal}>{text}</LinkButton>
  );
}
