import React, { useState } from "react";
import { Card, Grid, styled, Typography } from "@mui/material";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import { BookIcon, SlackIcon, FeedbackIcon } from "@cpt/components";
import { usePolling } from "hooks/usePolling";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { checkServerLicenseFulfilled, getLicenseXmlById } from "client/api/ServerLicenseApi";
import LoadingPlaceholder from "components/LoadingPlaceholder";
import { ResourceLink } from "components/ResourceLink/ResourceLink";
import { themeTokens } from "components/Theme/theme";
import { FormattedTextArea } from "components/form/FormattedTextArea";

type LicenseKeyViewProps = {
  licenseXml?: string;
  loading?: boolean;
  error?: Error;
  showTitle?: boolean;
  isFulfilled?: boolean;
};

const ResourceLinksContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
`;

export function LicenseKeyView(props: LicenseKeyViewProps) {
  const { showTitle, licenseXml, loading, isFulfilled, error } = props;
  return (
    <Card sx={{ padding: 2 }}>
      {showTitle && isFulfilled && (
        <Typography sx={{ color: themeTokens.color.text.secondary, marginBottom: 1 }}>License Key</Typography>
      )}
      {!isFulfilled && (
        <>
          <p>
            <strong>Your trial license is still being converted</strong>
          </p>
          <p>Your new XML key should be available in less than a minute</p>
        </>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <LoadingPlaceholder
            loading={loading || !isFulfilled}
            error={error}
            loadingLabel="Loading license key"
            secondaryLoadingLabel={!isFulfilled ? "approx. ~1min" : undefined}
            errorLabel="Error loading license key"
          >
            <FormattedTextArea
              value={licenseXml ?? " "}
              language="xml"
              defaultColorOverride={colorScales.purple[600]}
              hideLineNumbers
              wrapLongLines
            />
          </LoadingPlaceholder>
        </Grid>
        <Grid item xs={12} md={4}>
          <ResourceLinksContainer>
            <Typography variant="h3" sx={{ fontSize: 16, fontWeight: "700" }}>
              Resources
            </Typography>
            <ResourceLink href={ExternalUriMap.octopusComSupportRootPath} label="Support" icon={<FeedbackIcon />} />
            <ResourceLink href={ExternalUriMap.octopusComDocsRootPath} label="Docs" icon={<BookIcon />} />
            <ResourceLink
              href={ExternalUriMap.octopusCommunitySlackInvitePath}
              label="Community Slack"
              icon={<SlackIcon />}
            />
          </ResourceLinksContainer>
        </Grid>
      </Grid>
    </Card>
  );
}

interface SelfFetchingLicenseKeyViewProps {
  serverLicenseId: string;
  showTitle?: boolean;
}
export function SelfFetchingLicenseKeyView(props: SelfFetchingLicenseKeyViewProps) {
  const { serverLicenseId, showTitle } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [isFulfilled, setIsFullfilled] = useState<boolean | undefined>(undefined);
  const [licenseXml, setLicenseXml] = useState<string | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  const checkProvisioned = async () => {
    const check = await checkServerLicenseFulfilled(serverLicenseId);
    if (check) {
      try {
        const updatedLicense = await getLicenseXmlById(serverLicenseId);
        setIsFullfilled(true);
        setLoading(false);
        setLicenseXml(updatedLicense.licenseXml);
      } catch (e) {
        setLoading(false);
        if (e instanceof Error) {
          setError(e);
        }
      }
    }
  };

  usePolling(checkProvisioned, loading);

  return (
    <LicenseKeyView
      licenseXml={licenseXml}
      loading={loading}
      error={error}
      showTitle={showTitle}
      isFulfilled={isFulfilled}
    />
  );
}
