import type { ReactElement, ReactNode } from "react";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Avatar, Card, Grid, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { DateText, FontAwesomeSvgIcon } from "@cpt/components";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import useAsyncRequest from "hooks/useAsyncRequest";
import { usePolling } from "hooks/usePolling";
import { IsServerLicenseStripeSubscriptionLicensingChannel } from "utils/ServerLicenseLicensingChannelTypeHelpers";
import { isServerTrial } from "utils/isTrial";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { checkServerLicenseFulfilled, getLicenseXmlById, getServerLicenseById } from "client/api/ServerLicenseApi";
import { GreenButton } from "components/Button/GreenButton";
import { DetailOverviewBanner } from "components/DetailOverviewBanner/DetailOverviewBanner";
import { LicenseChannelChip } from "components/LicenseChannelChip/LicenseChannelChip";
import { themeTokens } from "components/Theme/theme";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";
import { LicenseKeyView } from "areas/serverLicenses/components/LicenseKeyView";
import { ServerLicenseHeader } from "areas/serverLicenses/components/ServerLicenseHeader";
import { ServerLicenseInstallation } from "areas/serverLicenses/detail/components/ServerLicenseInstallation/ServerLicenseInstallation";

export type IconAndTextProps = {
  icon: ReactElement;
  topText: ReactNode | string;
  bottomItem: ReactElement;
};

export function IconAndText(props: IconAndTextProps) {
  const { icon, topText, bottomItem } = props;

  return (
    <ListItem>
      <ListItemIcon>
        <Avatar
          sx={{
            bgcolor: (theme) => theme.palette.grey[200],
            width: 56,
            height: 56,
            marginRight: 2,
            color: themeTokens.color.text.secondary,
          }}
        >
          {icon}
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={topText}
        primaryTypographyProps={{
          fontWeight: 700,
          fontSize: "1.25rem",
          color: themeTokens.color.text.primary,
        }}
        secondary={bottomItem}
        secondaryTypographyProps={{ fontSize: "1rem", color: themeTokens.color.text.secondary }}
      />
    </ListItem>
  );
}

export type PlanCardProps = {
  serverLicense: ServerLicenseDto;
};

export function PlanCard(props: PlanCardProps) {
  const { serverLicense } = props;

  let expiryDateLabel: string | undefined = undefined;
  expiryDateLabel = "Expiry";

  const canUpgrade = isServerTrial(serverLicense.licensingChannel.channelType) && serverLicense.isFulfilled;

  return (
    <Card sx={{ padding: 2 }}>
      <Typography sx={{ color: themeTokens.color.text.secondary }}>Plan</Typography>
      <Grid container>
        <Grid item xs={6}>
          <IconAndText
            icon={<FontAwesomeSvgIcon icon={faServer} sx={{ fontSize: "16px" }} />}
            topText={
              <>
                Server{" "}
                {serverLicense.isFulfilled && (
                  <LicenseChannelChip channelType={serverLicense.licensingChannel.channelType} />
                )}
              </>
            }
            bottomItem={<>Serial: {serverLicense.serial}</>}
          />
          {canUpgrade && (
            <PolicyAuthorized
              permission={RbacPermissions.ServerLicense.ManageBilling}
              assetId={serverLicense.id}
              subscriptionGroupId={serverLicense.subscriptionGroup?.id}
            >
              <GreenButton
                sx={{ marginLeft: "88px" }}
                variant="contained"
                disableElevation
                component={RouterLink}
                to={ServerLicensesRoutesMap.detail(serverLicense.id).upgrade}
              >
                Upgrade Plan
              </GreenButton>
            </PolicyAuthorized>
          )}
        </Grid>
        <Grid item xs={6}>
          <IconAndText
            icon={<CalendarMonthIcon />}
            topText={
              <DateText
                date={serverLicense.expiryDate}
                fontWeight={"bold"}
                fontSize={"1.25rem"}
                color={themeTokens.color.text.primary}
              />
            }
            bottomItem={<>{expiryDateLabel}</>}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

interface LicenseXmlCardsProps {
  serverLicenseId: string;
  isFulfilled: boolean;
}

function LicenseXmlCards({ serverLicenseId, isFulfilled }: LicenseXmlCardsProps) {
  const { data, loading, error } = useAsyncRequest(getLicenseXmlById, serverLicenseId);
  return (
    <>
      <LicenseKeyView
        licenseXml={data?.licenseXml}
        loading={loading}
        isFulfilled={isFulfilled}
        error={error}
        showTitle
      />
      <ServerLicenseInstallation licenseXml={data?.licenseXml} loading={loading || !isFulfilled} error={error} />
    </>
  );
}

export function ServerLicenseOverview() {
  const { entity: serverLicense, update } = useEntity<ServerLicenseDto>();
  const { hasPermissionForAsset } = useUserContext();

  const canViewLicenseKeyXml = hasPermissionForAsset(
    RbacPermissions.ServerLicense.ViewLicenseKeyXml,
    serverLicense?.id,
    serverLicense?.subscriptionGroup?.id
  );
  const [loading, setLoading] = useState<boolean>(!serverLicense?.isFulfilled ?? true);

  const checkProvisioned = async () => {
    if (serverLicense?.id && !serverLicense?.isFulfilled) {
      const check = await checkServerLicenseFulfilled(serverLicense.id);
      if (check) {
        const updatedLicense = await getServerLicenseById(serverLicense.id);
        setLoading(false);
        update(updatedLicense);
      }
    }
  };

  usePolling(checkProvisioned, loading);

  if (!serverLicense) return <></>;
  return (
    <>
      <ServerLicenseHeader pageTitle="Overview" />
      <Stack spacing={2} sx={{ p: 2, backgroundColor: themeTokens.color.background.secondary.default }}>
        <DetailOverviewBanner
          id={serverLicense.id}
          freemium={
            IsServerLicenseStripeSubscriptionLicensingChannel(serverLicense.licensingChannel)
              ? serverLicense.licensingChannel.freemium
              : false
          }
          financePageRoute={ServerLicensesRoutesMap.detail(serverLicense.id).finance}
          type={"ServerLicense"}
          nextRenewalDate={
            IsServerLicenseStripeSubscriptionLicensingChannel(serverLicense.licensingChannel)
              ? serverLicense.licensingChannel.nextRenewalDate
              : undefined
          }
        />
        <PlanCard serverLicense={serverLicense} />
        {canViewLicenseKeyXml && (
          <LicenseXmlCards serverLicenseId={serverLicense.id} isFulfilled={serverLicense.isFulfilled} />
        )}
      </Stack>
    </>
  );
}
