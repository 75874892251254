import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { bold } from "fontWeights";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { SubmittableForm } from "components/form/SubmittableForm";

type AddUserDialogProps = {
  show: boolean;
  onCancel: () => Promise<void> | void;
  onSubmit: (email: string, firstName: string, lastName: string, role: string) => Promise<void> | void;
};

const grantValues = [
  {
    label: "Administrator",
    value: "Admin",
  },
  { label: "Technical Manager", value: "Tech" },
  { label: "Billing Manager", value: "Billing" },
] as const;

const emailOptions = {
  required: {
    value: true,
    message: "Please enter an email address for this user.",
  },
  pattern: {
    value: /^\w(?:\.?[\w%+-]+)*@\w(?:[\w-]*\.)+?[a-z]{2,}$/i,
    message: "Please enter a valid email address.",
  },
  maxLength: {
    value: 255,
    message: "Email address is too long, please enter an email address with less than 255 characters.",
  },
  minLength: {
    value: 4,
    message: "Email address is too short, please enter an email address with at least 4 characters.",
  },
};

const firstNameOptions = {
  required: {
    value: true,
    message: "Please enter a first name for this user.",
  },
  validate: {
    notEmpty: (val: string) => !!val.trim() || "Please enter a first name for this user.",
  },
  maxLength: {
    value: 30,
    message: "First name too long, please enter a name with less than 30 characters.",
  },
};

const lastNameOptions = {
  maxLength: {
    value: 30,
    message: "Last name too long, please enter a name with less than 30 characters.",
  },
  validate: {
    notEmpty: (val?: string) => !val || !!val.trim() || "Please enter a last name for this user.",
  },
};

export function InviteUserToSubscriptionGroup(props: AddUserDialogProps) {
  const { show, onCancel, onSubmit } = props;
  const [showRoleInfo, setShowRoleInfo] = React.useState(false);

  return (
    <Dialog
      open={show}
      hideBackdrop={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onCancel}
    >
      <DialogTitle id="alert-dialog-title">
        Invite a user to this subscription group
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p style={{ margin: 0, marginBottom: "16px", position: "relative", zIndex: "100" }}>
          <a href={ExternalUriMap.octopusComDocsCloudInvitingUsersPath} target={"_blank"} rel="noreferrer">
            Visit the docs
          </a>{" "}
          to learn about inviting users.
        </p>
        <SubmittableForm
          submitAction={async (data) => {
            const role = grantValues.find((x) => x.label === data.role);
            await onSubmit(data.email, data.firstName, data.lastName, role?.value || data.role);
          }}
          defaultValues={{
            email: "",
            firstName: "",
            lastName: "",
            role: grantValues[0].label,
          }}
          submitLabel={"Invite"}
        >
          {(formController) => {
            const { register, formState } = formController;
            return (
              <Grid container sx={{ pl: 3, pt: 3 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: bold }}>User details</Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                  <Grid item xs={12}>
                    <TextField
                      id={"email"}
                      required
                      fullWidth
                      label={"Email address"}
                      error={!!formState?.errors?.email}
                      helperText={formState?.errors?.email?.message}
                      {...register("email", emailOptions)}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={6} sx={{ pr: 0.5 }}>
                    <TextField
                      id={"firstName"}
                      required
                      fullWidth
                      label={"First name"}
                      error={!!formState?.errors?.firstName}
                      helperText={formState?.errors?.firstName?.message}
                      {...register("firstName", firstNameOptions)}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ pl: 0.5 }}>
                    <TextField
                      id={"lastName"}
                      fullWidth
                      label={"Last name"}
                      error={!!formState?.errors?.lastName}
                      helperText={formState?.errors?.lastName?.message}
                      {...register("lastName", lastNameOptions)}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ pt: 2 }}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: bold }}>Role</Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={grantValues}
                      defaultValue={grantValues[0]}
                      getOptionLabel={(x) => x.label}
                      disableClearable
                      renderInput={(params) => <TextField required {...params} {...register("role")} />}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                  <Grid item xs={12}>
                    <Alert
                      severity={"info"}
                      onClick={() => setShowRoleInfo(!showRoleInfo)}
                      action={
                        <IconButton aria-label="expand" color="inherit">
                          {showRoleInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      }
                    >
                      <AlertTitle>What does each role include?</AlertTitle>
                      <Collapse in={showRoleInfo}>
                        <Typography color={"inherit"}>
                          <strong>Administrator</strong>
                        </Typography>
                        <List
                          sx={{
                            listStyleType: "disc",
                            pl: 2,
                            "& .MuiListItem-root": {
                              display: "list-item",
                              padding: 0,
                            },
                          }}
                        >
                          <ListItem>
                            Authenticate to any subscription in this subscription group as part of the Octopus Managers
                            group
                          </ListItem>
                          <ListItem>Manage billing of any subscription in this subscription group</ListItem>
                          <ListItem>Manage configuration of any subscription in this subscription group</ListItem>
                          <ListItem>Manage access control for any subscription in this subscription group</ListItem>
                          <ListItem>Rename or delete this subscription group</ListItem>
                          <ListItem>Manage access control for this subscription group</ListItem>
                        </List>
                        <Typography color={"inherit"}>
                          <strong>Technical Manager</strong>
                        </Typography>
                        <List
                          sx={{
                            listStyleType: "disc",
                            pl: 2,
                            "& .MuiListItem-root": {
                              display: "list-item",
                              padding: 0,
                            },
                          }}
                        >
                          <ListItem>
                            Authenticate to any subscription in this subscription group as part of the Octopus Managers
                            group
                          </ListItem>
                          <ListItem>Manage configuration of any subscription in this subscription group</ListItem>
                          <ListItem>Manage access control for any subscription in this subscription group</ListItem>
                          <ListItem>Rename or delete this subscription group</ListItem>
                          <ListItem>Manage access control for this subscription group</ListItem>
                        </List>
                        <Typography color={"inherit"}>
                          <strong>Billing Manager</strong>
                        </Typography>
                        <List
                          sx={{
                            listStyleType: "disc",
                            pl: 2,
                            "& .MuiListItem-root": {
                              display: "list-item",
                              padding: 0,
                            },
                          }}
                        >
                          <ListItem>Manage billing of any subscription in this subscription group</ListItem>
                        </List>
                      </Collapse>
                    </Alert>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </SubmittableForm>
      </DialogContent>
    </Dialog>
  );
}
