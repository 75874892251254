import React, { useMemo } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import type { ProductType } from "shared/ProductType";
import { useContactSalesDialog } from "components/Dialogs/ContactSalesDialog/ContactSalesDialogProvider";
import { LinkButton } from "components/LinkButton/LinkButton";
import type { CloudSubscriptionProps } from "areas/cloudSubscriptions/CloudSubscriptionProps";
import { isCloudSubscriptionProps } from "areas/cloudSubscriptions/CloudSubscriptionProps";
import { CustomerPortalLink } from "areas/cloudSubscriptions/components/CustomerPortalLink";
import type { ServerLicenseProps } from "areas/serverLicenses/ServerLicenseProps";

export function OrdersFinanceLine(props: CloudSubscriptionProps | ServerLicenseProps) {
  const { showDialog } = useContactSalesDialog();
  const {
    isStripeSubscriptionLicensingChannel,
    productType,
    productId,
  }: {
    isStripeSubscriptionLicensingChannel: boolean;
    productType: ProductType;
    productId: string;
  } = useMemo(() => {
    if (isCloudSubscriptionProps(props)) {
      return {
        isStripeSubscriptionLicensingChannel:
          props.cloudSubscription.licensingChannel.channelType ===
          "StripeSubscriptionCloudSubscriptionLicensingChannelDto",
        productType: "CloudSubscription",
        productId: props.cloudSubscription.id,
      };
    } else {
      return {
        isStripeSubscriptionLicensingChannel:
          props.serverLicense.licensingChannel.channelType === "StripeSubscriptionServerLicensingChannelDto",
        productType: "ServerLicense",
        productId: props.serverLicense.id,
      };
    }
  }, [props]);

  return (
    <>
      <Grid item sm={3} sx={{ padding: 3 }}>
        <Typography fontWeight={700}>Orders</Typography>
      </Grid>
      {isStripeSubscriptionLicensingChannel && (
        <>
          <Grid item sm={6} sx={{ padding: 3 }}>
            <Typography>Recent orders</Typography>
          </Grid>
          <Grid item sm={3} sx={{ textAlign: "right", padding: 3 }}>
            <Stack spacing={1} sx={{ alignItems: "flex-end" }}>
              <CustomerPortalLink text={"View subscription"} productType={productType} productId={productId} />
            </Stack>
          </Grid>
          <Grid item sm={3} sx={{ padding: 3 }}></Grid>
        </>
      )}
      <Grid item sm={5} sx={{ padding: 3 }}>
        <Typography>Older orders</Typography>
      </Grid>
      <Grid item sm={4} sx={{ textAlign: "right", padding: 3 }}>
        <LinkButton onClick={() => showDialog({ reason: "other" })}>Contact Sales</LinkButton>
      </Grid>
    </>
  );
}
