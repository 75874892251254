import React from "react";
import { Grid, Typography } from "@mui/material";
import type {
  CloudSubscriptionDto,
  SalesforceContractedCloudSubscriptionLicensingChannelDto,
} from "client/api/CloudSubscriptionApi";
import { ChangePlanFinanceLine } from "components/ChangePlanFinanceLine/ChangePlanFinanceLine";
import { GridDivider } from "components/GridDivider/GridDivider";
import { LicensedEntitlementsView } from "components/LicensedEntitlementsView/LicensedEntitlementsView";
import { OrdersFinanceLine } from "components/OrdersFinanceLine/OrdersFinanceLine";
import type { LicensingChannelViewProps } from "areas/cloudSubscriptions/detail/components/finance/LicensingChannelViewProps";

type EntitlementViewProps = { cloudSubscription: CloudSubscriptionDto };
function EntitlementView(props: EntitlementViewProps) {
  const { cloudSubscription } = props;
  return <LicensedEntitlementsView license={cloudSubscription} />;
}

export function SalesforceContractedLicensingChannelView(
  props: LicensingChannelViewProps<SalesforceContractedCloudSubscriptionLicensingChannelDto>
) {
  const { cloudSubscription } = props;
  return (
    <>
      {/*<CurrentPlanLine>*/}
      <>
        <Grid item sm={3} sx={{ padding: 3 }}>
          <Typography fontWeight={700}>Current plan</Typography>
        </Grid>
        <Grid item sm={6} sx={{ padding: 3 }}>
          <Typography>Cloud</Typography>
        </Grid>
        <Grid item sm={3} sx={{ padding: 3 }}></Grid>
      </>
      <GridDivider />
      <ChangePlanFinanceLine />
      <GridDivider />
      <EntitlementView cloudSubscription={cloudSubscription} />
      <GridDivider />
      <OrdersFinanceLine cloudSubscription={cloudSubscription} />
    </>
  );
}
