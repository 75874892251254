import React, { useState } from "react";
import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  UseAutocompleteProps,
  InputBaseComponentProps,
} from "@mui/material";
import { Autocomplete, CircularProgress, InputAdornment, styled } from "@mui/material";
import { matchSorter } from "match-sorter";
import { CheckoutTextInput } from "./CheckoutTextInput";
import { ChevronDownIcon } from "./ChevronDownIcon";

export interface AutocompleteOption {
  label: string;
  value: unknown;
}

interface CheckoutAutocompleteInputProps {
  id?: string;
  options?: AutocompleteOption[];
  error?: boolean;
  required?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: unknown,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown> | undefined
  ) => void;
  freeSolo?: boolean;
  value?: AutocompleteOption | string | null;
  isOptionEqualToValue?: (option: unknown, value: unknown) => boolean;
  loading?: boolean;
  filterOptions?: UseAutocompleteProps<
    unknown,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >["filterOptions"];
  getOptionKey?: (option: unknown) => string | number;
  InputProps?: InputBaseComponentProps;
}

const AutocompleteInput = styled(Autocomplete)`
  .MuiInput-root {
    padding: 0px;
  }
  .MuiInput-input.MuiInput-input {
    box-sizing: border-box;
    padding: 13px 20px;
    padding-right: 50px;
    height: 100%;
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  position: absolute;
  right: 0;
  pointer-events: none;
  color: rgba(17, 24, 29, 0.8);
`;

const filterOptionsDefault = (options: unknown[], state: { inputValue: string }) => {
  return matchSorter(options, state.inputValue, { keys: ["label"] });
};

export function CheckoutAutocompleteInput(props: CheckoutAutocompleteInputProps) {
  const {
    id,
    options = [],
    error,
    placeholder,
    fullWidth = true,
    onInputChange,
    onChange,
    freeSolo = false,
    value,
    isOptionEqualToValue,
    filterOptions = filterOptionsDefault,
    loading,
    getOptionKey,
    InputProps,
  } = props;

  const [open, setOpen] = useState(false);
  const displayLoadingState = open && loading;

  return (
    <AutocompleteInput
      id={id}
      options={options}
      filterOptions={filterOptions}
      onInputChange={onInputChange}
      onChange={onChange}
      freeSolo={freeSolo}
      value={value}
      isOptionEqualToValue={isOptionEqualToValue}
      loading={loading}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <CheckoutTextInput
            placeholder={placeholder}
            error={error}
            endAdornment={
              <StyledInputAdornment position={"end"}>
                {displayLoadingState ? <CircularProgress color="inherit" size={18} /> : <ChevronDownIcon />}
              </StyledInputAdornment>
            }
            fullWidth={fullWidth}
            inputProps={{
              ...InputProps,
              ...params.inputProps,
              ref: (el: JSX.Element) => {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                (params.inputProps.ref as React.MutableRefObject<unknown>).current = el;
                InputProps && InputProps.ref(el);
              },
            }}
          />
        </div>
      )}
      getOptionKey={getOptionKey}
      onKeyDown={(event) => {
        // prefer home and end keys to change cursor position of the text input rather than active item of the listbox
        if (event.key === "Home") {
          event.defaultMuiPrevented = true;
        } else if (event.key === "End") {
          event.defaultMuiPrevented = true;
        }
      }}
    />
  );
}
