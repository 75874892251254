import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Box, Stack, Divider, FormControlLabel, Alert, CircularProgress, Link } from "@mui/material";
import { formatMoney } from "utils/formatters/MoneyFormatter";
import type { OrderPreview } from "client/api/PurchaseApi";
import { themeTokens } from "components/Theme/theme";
import { CustomErrorAlert, ErrorMessageTryAgainOrContactSupport } from "components/alert/CustomErrorAlert";
import { CheckoutCheckbox } from "areas/checkout/components/CheckoutCheckbox";
import type { OrderPreviewErrorReason } from "areas/purchasing/Checkout";
import { SummaryLine } from "./SummaryLine";
import { TaxSummary } from "./TaxSummary";

interface OrderPreviewSummaryProps {
  isTaxExempted: boolean;
  onToggleTaxExemption: () => void;
  changePlanRoute: string;
  orderPreview: OrderPreview;
  isFetchingTax: boolean;
  hasMinimumTaxInfo: boolean;
  errorReason: OrderPreviewErrorReason | null;
}

const SubscriptionAgreement = ({ planName }: { planName: string }) => {
  return (
    <Typography sx={{ fontSize: "14px", color: "#586978", textAlign: "center" }}>
      By purchasing {planName} you accept our{" "}
      <Link href={"https://octopus.com/legal/customer-agreement"} target={"_blank"}>
        customer agreement
      </Link>
      .
    </Typography>
  );
};

export function OrderPreviewSummary(props: OrderPreviewSummaryProps) {
  const {
    onToggleTaxExemption,
    changePlanRoute,
    orderPreview,
    isTaxExempted,
    isFetchingTax,
    hasMinimumTaxInfo,
    errorReason,
  } = props;

  const discount = orderPreview.discountAmount;

  const basePriceDisplayString = formatMoney(orderPreview.totalAmountExcludingTax, { includeTrailingZeros: false });
  const planPriceDisplayString = formatMoney(orderPreview.totalAmountExcludingTax, { includeTrailingZeros: true });
  const discountDisplayString = formatMoney(discount, { includeTrailingZeros: true });
  const subTotalDisplayString = planPriceDisplayString;

  const totalDisplayString = formatMoney(orderPreview.totalAmount, {
    includeTrailingZeros: true,
  });

  return (
    <>
      {errorReason && errorReason.statusCode !== 400 && (
        <CustomErrorAlert message={<ErrorMessageTryAgainOrContactSupport />} />
      )}
      <Box padding={3}>
        <Stack divider={<Divider sx={{ color: "#E6E8EA" }} />} spacing={2}>
          <Box>
            <Stack
              direction={"row"}
              sx={{ marginBottom: "10px" }}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant={"h6"} color={themeTokens.color.text.primary}>
                Order Summary
              </Typography>
              <RouterLink
                to={changePlanRoute}
                style={{ textDecoration: "underline", color: themeTokens.color.text.link.default }}
              >
                Change plan
              </RouterLink>
            </Stack>
            <Typography
              variant={"body1"}
              fontSize={18}
              color={"#586978"}
              data-testid="order-summary-header-product-name"
            >
              {orderPreview.productName}
            </Typography>
            <Box color={"#11181D"} data-testid="order-summary-header-product-price">
              <Typography component={"span"} fontWeight={700} fontSize={32}>
                {basePriceDisplayString}
              </Typography>
              <Typography component={"span"} marginLeft={"5px"} variant={"body1"} fontSize={20}>
                &nbsp;{orderPreview.currencyCode} per year
              </Typography>
            </Box>
          </Box>
          <Stack spacing={1}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Box>
                <Typography
                  variant={"body1"}
                  color={themeTokens.color.text.primary}
                  data-testid="order-summary-details-product-name"
                >
                  {orderPreview.productName}
                </Typography>
                <Typography fontSize={12} color={themeTokens.color.text.primary}>
                  {orderPreview.chargeDescription}
                </Typography>
              </Box>
              <Typography
                variant={"body1"}
                color={themeTokens.color.text.primary}
                data-testid="order-summary-details-product-price"
              >
                {planPriceDisplayString}
              </Typography>
            </Stack>
            <SummaryLine label={"Billed yearly"} sx={{ color: "#586978" }} value={""} />
            {discount != 0 && (
              <SummaryLine label={"Annual Discount"} value={`${discountDisplayString}`} sx={{ color: "#00B065" }} />
            )}
          </Stack>
          <Stack spacing={1}>
            <SummaryLine label={"Subtotal"} value={`${subTotalDisplayString}`} />
            <>
              <TaxSummary
                isFetchingTax={isFetchingTax}
                hasMinimumTaxInfo={hasMinimumTaxInfo}
                errorReason={errorReason}
                taxItems={orderPreview.taxItems}
              />
              <FormControlLabel
                control={
                  <CheckoutCheckbox id={"tax-exemption"} checked={isTaxExempted} onChange={onToggleTaxExemption} />
                }
                label={
                  <Typography sx={{ marginLeft: "16px" }} color={themeTokens.color.text.primary} variant={"body1"}>
                    I have a tax exemption certificate
                  </Typography>
                }
              />
              {isTaxExempted && (
                <Alert severity={"info"}>Our sales team will process your order to sight this certificate.</Alert>
              )}
            </>
          </Stack>
          <Box>
            <SummaryLine
              label={"Total"}
              value={
                !hasMinimumTaxInfo ? (
                  "$---.--"
                ) : isFetchingTax ? (
                  <CircularProgress size={16} />
                ) : errorReason ? (
                  "$---.--"
                ) : (
                  `${totalDisplayString}`
                )
              }
              sx={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}
              valueTestId="order-summary-total-price"
            />
          </Box>
        </Stack>
      </Box>
      <Box padding={3} sx={{ borderTop: "1px solid #E6E8EA" }}>
        <SubscriptionAgreement planName={orderPreview.productName} />
      </Box>
    </>
  );
}
