import React from "react";
import { ChangePlanFinanceLine } from "components/ChangePlanFinanceLine/ChangePlanFinanceLine";

export function ZuoraServerLicensingChannelView() {
  return (
    <>
      <ChangePlanFinanceLine />
    </>
  );
}
