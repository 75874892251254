import React from "react";
import { Box, Stack } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import type { OrderPreview } from "client/api/PurchaseApi";
import { CustomErrorAlert } from "components/alert/CustomErrorAlert";
import type { OrderPreviewErrorReason } from "areas/purchasing/Checkout";
import { OrderPreviewSummary } from "./OrderPreviewSummary";

interface CheckoutOrderSummaryProps {
  subscriptionType: "cloud" | "selfhosted";
  changePlanRoute: string;
  isTaxExempted: boolean;
  onToggleTaxExemption: () => void;
  isFetchingTax: boolean;
  orderPreview: OrderPreview | null;
  errorReason: OrderPreviewErrorReason | null;
  hasMinimumTaxInfo: boolean;
}

const Skeleton = () => {
  return (
    <Box padding={3}>
      <Stack direction={"column"}>
        <LinearProgress />
      </Stack>
    </Box>
  );
};

export function CheckoutOrderSummary(props: CheckoutOrderSummaryProps) {
  const {
    changePlanRoute,
    orderPreview,
    isTaxExempted,
    onToggleTaxExemption,
    errorReason,
    isFetchingTax,
    hasMinimumTaxInfo,
  } = props;

  if (errorReason && !orderPreview) {
    return <CustomErrorAlert message={errorReason.message} />;
  }

  if (orderPreview) {
    return (
      <OrderPreviewSummary
        changePlanRoute={changePlanRoute}
        orderPreview={orderPreview}
        isTaxExempted={isTaxExempted}
        onToggleTaxExemption={onToggleTaxExemption}
        isFetchingTax={isFetchingTax}
        hasMinimumTaxInfo={hasMinimumTaxInfo}
        errorReason={errorReason}
      />
    );
  }

  return <Skeleton />;
}
